

.projectPicture {
    margin-top:10px;
    margin-bottom:35px;
}

.projectPicture img {
    width: 90%;
}

.projects li {
    font-size: 16px;
}

.projects ul {
    list-style: none; /* Remove default bullets */
}

.projects ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #ab1b1f; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 10px; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.projectIndependet {
    margin-top: 60px;
    background: linear-gradient(93deg, #FFFFFF 29%, #feb356 30%, #FFFFFF 31%);
}

@media screen and (max-width: 992px) {
    .projectIndependet {
        background: white;
    }
}

@media screen and (max-width: 500px) {
    .gunImage {
        transform: scale(0.8);
        object-fit: cover;
    }
}

.projectGalery {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.projectIndependet .logo p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.projectIndependet .description {
    color: black;
}

.projectIndependet .description h3{
    color: black;
    margin-top: 15px;
}

.projectIndependet .description p{
    font-size: 15px;
}

.projectIndependet .description li{
    font-size: 15px;
}

.projectIndependet img {
    background-color: transparent;
}

.projects .divider {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: #5E5E5E solid 2px;
}

.projects .item .name {
    font-size:26px;
    margin-bottom:8px;
    margin-top:10px;
    color:inherit;
}

.projects .item .description {
    font-size:16px;
    margin-top:15px;
    margin-bottom:10px;
}