
.companyBanner {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

@media screen and (max-width: 767px) {
    .companyBanner {
        width: 100%;
        height: 80px;
        object-fit: cover;
    }
}