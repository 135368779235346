/* navbar */
.navbar-default {
    background-color: #FFFFFF;
    border-bottom: #5E5E5E solid 2px;
}
/* Title */
.navbar-default .navbar-brand {
    color: #777;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #5E5E5E;
}
/* Link */
.navbar-default .navbar-nav > li > a {
    color: #424343;
    font-weight: bold;
    text-align: center;
}
.navbar-default .navbar-nav > li > a:hover {
    color: #FFFFFF;
    background-color: #ab1b1f;
    border-radius: 10px;

}
.navbar-default .navbar-nav > .active > a {
    color: #ab1b1f;
}

.navbar-default .navbar-nav > .active > a:hover {
    color: #FFFFFF;
    background-color: #ab1b1f;
}

.navbar-default .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#5E5E5E' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media screen and (min-width: 767px) {
    .navbar-default .navbar-nav > li > a {
        margin-left: 20px;
    }
}

.upButton {
    color: #ab1b1f;
    font-size: 38px;
}

.upButton :hover {
    color: #9B1B1E;
}

@media screen and (max-width: 767px) {
    .navbar-default .navbar-nav {
        border-top: #ab1b1f solid;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
    }
}