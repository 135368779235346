body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* navbar */
.navbar-default {
    background-color: #FFFFFF;
    border-bottom: #5E5E5E solid 2px;
}
/* Title */
.navbar-default .navbar-brand {
    color: #777;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #5E5E5E;
}
/* Link */
.navbar-default .navbar-nav > li > a {
    color: #424343;
    font-weight: bold;
    text-align: center;
}
.navbar-default .navbar-nav > li > a:hover {
    color: #FFFFFF;
    background-color: #ab1b1f;
    border-radius: 10px;

}
.navbar-default .navbar-nav > .active > a {
    color: #ab1b1f;
}

.navbar-default .navbar-nav > .active > a:hover {
    color: #FFFFFF;
    background-color: #ab1b1f;
}

.navbar-default .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#5E5E5E' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media screen and (min-width: 767px) {
    .navbar-default .navbar-nav > li > a {
        margin-left: 20px;
    }
}

.upButton {
    color: #ab1b1f;
    font-size: 38px;
}

.upButton :hover {
    color: #9B1B1E;
}

@media screen and (max-width: 767px) {
    .navbar-default .navbar-nav {
        border-top: #ab1b1f solid;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
    }
}

.homeHeader h3 {
  margin-top: 5vh;
  margin-bottom: 1vh;
}

.homeHeader h4 {
    margin-top: 2vh;
}

.redFont {
    color: #ab1b1f;
    font-weight: bold;
}

.BoldFont {
    font-size: 16px;
    font-weight: bold;
    margin-right: 7px;
}
/*object-position: 5px 10%;*/
.homeBanner {
    width: 100%;
    height: 35vh;
}

.homeBanner.ui {
    object-fit: cover;
    object-position: 0px 45%;
}

.homeBanner.d3 {
    object-fit: cover;
    object-position: 0px 80%;
}


.margin40 {
    margin-top: 40px;
}

.skillBox {
    border: #aeaeae dashed 1px;
    border-radius: 10px;
    margin-right: 6px;
    margin-left: 6px;
}

.fixHowirizonalScroll {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 767px) {
    .homeHeader h3 {
        margin-top: 8vh;
        margin-bottom: 3vh;
    }
    .homeHeader h6 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .homeBanner {
        width: 100%;
        height: 30vh;
    }
}

@media screen and (max-width: 450px) {
    .homeBanner {
        width: 100%;
        height: 20vh;
    }
}

.features-boxed .item {
    text-align:center;
}

.features-boxed .item .box {
    text-align:center;
    padding:5px;
    background-color:#fff;
}

.features-boxed .item .icon {
    font-size:40px;
    color:#ab1b1f;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
}

.features-boxed .item .name {
    font-weight:bold;
    font-size:18px;
    margin-top:0;
    color:inherit;
}

.features-boxed .item .description {
    font-size:15px;
}

.projects-boxed .item {
    text-align:center;
    -webkit-filter: grayscale(10%);
            filter: grayscale(10%);
}


.projects-boxed .item .box {
    text-align:center;
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    text-align: center;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; /* Animation */
    color: white;
}

.label-inside-project {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    max-width: 350px;
    border-radius: 15px;
    position: absolute;
    text-align: left;
    bottom: 18px;
    left: 16px;
}

.projects-boxed .item .icon {
    overflow: hidden;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
}

.projects-boxed .item .icon img {
    width: 100%;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.projects-boxed .item img:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.projects-boxed .item .icon :hover {
}

.projects-boxed .item .name {
    font-size:20px;
    font-weight: bold;
    margin-bottom:8px;
    margin-top:0;
    color:inherit;
}

.projects-boxed .item .description {
    font-size:15px;
    margin-top:15px;
    margin-bottom:10px;
}


@media screen and (max-width: 1000px) {
    .label-inside-project {
        max-width: 200px;
    }
    .projects-boxed .item .name {
        font-size:16px;
        font-weight: bold;
        margin-bottom:8px;
        margin-top:0;
        color:inherit;
    }

    .projects-boxed .item .description {
        font-size:12px;
        margin-top:10px;
        margin-bottom:10px;
    }
}

.companyBanner {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

@media screen and (max-width: 767px) {
    .companyBanner {
        width: 100%;
        height: 80px;
        object-fit: cover;
    }
}


.contactForm h3 {
    text-align: center;
}

.contactForm label {
    font-weight: bold;
}

.contactForm {
    background-color: #5E5E5E;
    padding: 15px;
    margin-top: 50px;
}

.contactForm .form-group {
    max-width: 500px;
}

.fastContact {
    color: white;
    margin-left: 50px;
    margin-right: -30px;
}

@media screen and (max-width: 992px) {
    .fastContact {
        margin-left: 0px;
        text-align: center;
        margin-right: -0px;
        margin-bottom: 30px;
    }
}

.contactForm .btn-primary {
    color: #fff;
    background-color: #ab1b1f;
    border-color: #ab1b1f;
}

.contactForm .btn-primary:hover {
    color: #fff;
    background-color: #9B1B1E;
    border-color: #9B1B1E;
}

.contactForm form{
    text-align: -webkit-center;
}

.coypright {
    border-top: white solid 2px;
    color: white;
}

.socialNetwork {
    font-size: 32px;
    margin-left: 15px;
    cursor: pointer;
}

.socialNetwork :hover {
    color: #ab1b1f;
}

.contacts div {
    font-size: 14px;
}

.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #ab1b1f; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.projectPicture {
    margin-top:10px;
    margin-bottom:35px;
}

.projectPicture img {
    width: 90%;
}

.projects li {
    font-size: 16px;
}

.projects ul {
    list-style: none; /* Remove default bullets */
}

.projects ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #ab1b1f; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 10px; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.projectIndependet {
    margin-top: 60px;
    background: -webkit-linear-gradient(357deg, #FFFFFF 29%, #feb356 30%, #FFFFFF 31%);
    background: linear-gradient(93deg, #FFFFFF 29%, #feb356 30%, #FFFFFF 31%);
}

@media screen and (max-width: 992px) {
    .projectIndependet {
        background: white;
    }
}

@media screen and (max-width: 500px) {
    .gunImage {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        object-fit: cover;
    }
}

.projectGalery {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.projectIndependet .logo p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.projectIndependet .description {
    color: black;
}

.projectIndependet .description h3{
    color: black;
    margin-top: 15px;
}

.projectIndependet .description p{
    font-size: 15px;
}

.projectIndependet .description li{
    font-size: 15px;
}

.projectIndependet img {
    background-color: transparent;
}

.projects .divider {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: #5E5E5E solid 2px;
}

.projects .item .name {
    font-size:26px;
    margin-bottom:8px;
    margin-top:10px;
    color:inherit;
}

.projects .item .description {
    font-size:16px;
    margin-top:15px;
    margin-bottom:10px;
}
