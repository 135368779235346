

.contactForm h3 {
    text-align: center;
}

.contactForm label {
    font-weight: bold;
}

.contactForm {
    background-color: #5E5E5E;
    padding: 15px;
    margin-top: 50px;
}

.contactForm .form-group {
    max-width: 500px;
}

.fastContact {
    color: white;
    margin-left: 50px;
    margin-right: -30px;
}

@media screen and (max-width: 992px) {
    .fastContact {
        margin-left: 0px;
        text-align: center;
        margin-right: -0px;
        margin-bottom: 30px;
    }
}

.contactForm .btn-primary {
    color: #fff;
    background-color: #ab1b1f;
    border-color: #ab1b1f;
}

.contactForm .btn-primary:hover {
    color: #fff;
    background-color: #9B1B1E;
    border-color: #9B1B1E;
}

.contactForm form{
    text-align: -webkit-center;
}

.coypright {
    border-top: white solid 2px;
    color: white;
}

.socialNetwork {
    font-size: 32px;
    margin-left: 15px;
    cursor: pointer;
}

.socialNetwork :hover {
    color: #ab1b1f;
}

.contacts div {
    font-size: 14px;
}

.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #ab1b1f; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}