
.homeHeader h3 {
  margin-top: 5vh;
  margin-bottom: 1vh;
}

.homeHeader h4 {
    margin-top: 2vh;
}

.redFont {
    color: #ab1b1f;
    font-weight: bold;
}

.BoldFont {
    font-size: 16px;
    font-weight: bold;
    margin-right: 7px;
}
/*object-position: 5px 10%;*/
.homeBanner {
    width: 100%;
    height: 35vh;
}

.homeBanner.ui {
    object-fit: cover;
    object-position: 0px 45%;
}

.homeBanner.d3 {
    object-fit: cover;
    object-position: 0px 80%;
}


.margin40 {
    margin-top: 40px;
}

.skillBox {
    border: #aeaeae dashed 1px;
    border-radius: 10px;
    margin-right: 6px;
    margin-left: 6px;
}

.fixHowirizonalScroll {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 767px) {
    .homeHeader h3 {
        margin-top: 8vh;
        margin-bottom: 3vh;
    }
    .homeHeader h6 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .homeBanner {
        width: 100%;
        height: 30vh;
    }
}

@media screen and (max-width: 450px) {
    .homeBanner {
        width: 100%;
        height: 20vh;
    }
}

.features-boxed .item {
    text-align:center;
}

.features-boxed .item .box {
    text-align:center;
    padding:5px;
    background-color:#fff;
}

.features-boxed .item .icon {
    font-size:40px;
    color:#ab1b1f;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
}

.features-boxed .item .name {
    font-weight:bold;
    font-size:18px;
    margin-top:0;
    color:inherit;
}

.features-boxed .item .description {
    font-size:15px;
}

.projects-boxed .item {
    text-align:center;
    filter: grayscale(10%);
}


.projects-boxed .item .box {
    text-align:center;
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    text-align: center;
    transition: transform .2s; /* Animation */
    color: white;
}

.label-inside-project {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    max-width: 350px;
    border-radius: 15px;
    position: absolute;
    text-align: left;
    bottom: 18px;
    left: 16px;
}

.projects-boxed .item .icon {
    overflow: hidden;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
}

.projects-boxed .item .icon img {
    width: 100%;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.projects-boxed .item img:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.projects-boxed .item .icon :hover {
}

.projects-boxed .item .name {
    font-size:20px;
    font-weight: bold;
    margin-bottom:8px;
    margin-top:0;
    color:inherit;
}

.projects-boxed .item .description {
    font-size:15px;
    margin-top:15px;
    margin-bottom:10px;
}


@media screen and (max-width: 1000px) {
    .label-inside-project {
        max-width: 200px;
    }
    .projects-boxed .item .name {
        font-size:16px;
        font-weight: bold;
        margin-bottom:8px;
        margin-top:0;
        color:inherit;
    }

    .projects-boxed .item .description {
        font-size:12px;
        margin-top:10px;
        margin-bottom:10px;
    }
}